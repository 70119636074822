import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/TeamMemberLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Corry joined CLHbid.com in the role of Public Relations in 2019. She has her Bachelor of Education Degree from the U of A and has had both teaching and leadership roles with Peace Wapiti School Division for over 20 years.`}</p>
    <p>{`Corry lives on a farm in the Teepee Creek area but her farming roots go back to being raised on the family farm in Hawk Hills just north of Manning, AB. It was there that she first learned from her family what it meant to be part of the community and to be a volunteer. She was taught the fundamental values of integrity, trust, and compassion that are so common with the philosophy of CLHbid.com. Corry understands firsthand what it was like for her parents to sell the family farm that they worked so hard to build. She appreciates how the farmer is honored in the entire CLHbid.com process.`}</p>
    <p>{`Working with technology, graphics, and marketing has always been a passion for Corry, and something she pursued while volunteering in various community events and organizations. Her ability to work with people and her organizational skills using technology are areas she is often commended and respected for.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      