import React from "react";
import { MDXProvider } from "@mdx-js/react";

import SEO from "./SEO";
import * as shortcodes from "./Shortcodes";
import SectionLayout from "./SectionLayout";

import email_icon from "../images/email.svg";
import phone_icon from "../images/phone.svg";

const TeamMemberLayout: React.FC<{
  pageContext: {
    frontmatter: {
      name: string;
      title: string;
      section: string;
      headshot: string;
      email?: string;
    };
  };
  children: React.ReactNode;
}> = ({ children, pageContext }) => {
  const { name, title, section, headshot, email } = pageContext.frontmatter;

  return (
    <SectionLayout title={title} id={section}>
      <SEO title={`About ${name}, ${title}`} />
      <div
        itemScope
        itemType="https://schema.org/Person"
        className="md:flex justify-between mb-8"
      >
        <h1 className="text-2xl mb-2 md:mb-0">
          <span itemProp="name">{name}</span>,{" "}
          <span itemProp="jobTitle">{title}</span>
        </h1>
        {email && (
          <div className="flex flex-wrap gap-x-4 md:gap-x-6 items-center">
            <a className="flex text-gray-700" href={`mailto:${email}`}>
              <img alt="" className="pr-1" src={email_icon} />
              <span itemProp="email">{email}</span>
            </a>
            <a className="flex text-gray-700" href="tel:+1–866–263–7480">
              <img alt="" className="pr-1" src={phone_icon} />
              <span itemProp="telephone">1–866–263–7480</span>
            </a>
          </div>
        )}
      </div>
      <img
        className="mr-4 mb-4 md:w-64 md:float-left"
        alt={`${name} headshot`}
        src={headshot}
      />
      <MDXProvider components={shortcodes}>{children}</MDXProvider>
    </SectionLayout>
  );
};

export default TeamMemberLayout;
